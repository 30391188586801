import {
  DISPLAY_DIRECT_BOOKING,
  DISPLAY_MESSAGE_SERVICE_MODAL,
  DISPLAY_ONDEMAND_MODAL,
  DISPLAY_QUICK_ORDER,
  DISPLAY_UNGRADED_ASSIGNMENTS,
  TOGGLE_LOADING_OVERLAY,
} from './ActionTypes';

function toggleLoadingOverlay(show) {
  return dispatch => {
    dispatch({
      type: TOGGLE_LOADING_OVERLAY,
      showLoadingOverlay: show,
    });
  };
}

export function showLoadingOverlay() {
  return dispatch => {
    dispatch(toggleLoadingOverlay(true));
  };
}

export function hiddenLoadingOverlay() {
  return dispatch => {
    dispatch(toggleLoadingOverlay(false));
  };
}

export function displayOnDemand(show) {
  return dispatch => {
    dispatch({
      type: DISPLAY_ONDEMAND_MODAL,
      display: show,
    });
  };
}

export function displayMessageService(show) {
  return dispatch => {
    dispatch({
      type: DISPLAY_MESSAGE_SERVICE_MODAL,
      show,
    });
  };
}

export function displayQuickOrder(show) {
  return dispatch => {
    dispatch({
      type: DISPLAY_QUICK_ORDER,
      showQuickOrder: show,
    });
  };
}

export function displayDirectBooking(show) {
  return dispatch => {
    dispatch({
      type: DISPLAY_DIRECT_BOOKING,
      showDirectBooking: show,
    });
  };
}

export function displayUngradedAssignments(show) {
  return dispatch => {
    dispatch({
      type: DISPLAY_UNGRADED_ASSIGNMENTS,
      displayUngradedAssignments: show,
    });
  };
}
