import moment from 'moment';
import React, { useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import {
  AssignmentStatus,
  IfComponent,
  Modal,
  Translate,
  TransvoiceButton,
} from '../../../Share/components';
import {
  assignmentTimeFormat,
  getDateFormated,
  isIE,
  shouldDisplayRatingElement,
} from '../../../Share/utils';
import PrintableAssignmentDetail from './PrintableAssignmentDetail';
import { useSelector } from 'react-redux';

function AssignmentFullDetailed({
  onHandleModal,
  showModal,
  assignment,
  handleClickCancel,
  status,
  isCancelableAssigment,
  isShowInterpreterElement,
  handleClickTimeReport,
}) {
  const componentRef = useRef(null);
  const scrollRef = useRef(null);
  const genderIdtoString = genderId => {
    let genderString = Translate({ content: 'general.any' });
    switch (genderId) {
      case 1:
        genderString = Translate({ content: 'general.male' });
        break;
      case 2:
        genderString = Translate({ content: 'general.female' });
        break;

      default:
        genderString = 'Ej angivet';
        break;
    }
    return genderString;
  };
  const requestedInterpreterPreferedLevelString = preferedLevel => {
    let preferedLevelString = '';
    switch (preferedLevel) {
      case 2:
        preferedLevelString = 'Önskemål';
        break;
      case 1:
        preferedLevelString = 'Endast';
        break;
      case 3:
        preferedLevelString = 'Vidtalad';
        break;
      default:
        break;
    }
    return preferedLevelString;
  };

  const services = useSelector(state => state.services.services);
  let altService = {};
  if (assignment.ServiceAlternatives) {
    altService = services.find(
      service =>
        service.ServiceIdentifier ===
        assignment.ServiceAlternatives[0].ServiceIdentifier,
    );
  }

  let competencePreference = '';
  if (
    assignment.DoRequireCompetenceLevelOrHigher &&
    !assignment.DoRequireExactCompetenceLevel
  )
    competencePreference = 'Krav på lägsta nivå';
  if (
    !assignment.DoRequireCompetenceLevelOrHigher &&
    assignment.DoRequireExactCompetenceLevel
  )
    competencePreference = 'Endast';
  if (
    !assignment.DoRequireCompetenceLevelOrHigher &&
    !assignment.DoRequireExactCompetenceLevel
  )
    competencePreference = Translate({
      content: 'singleAssignment.notARequirment',
    });

  const secondaryMessage = (
    <>
      {assignment.ConsolidatedOrderStatus?.ConsolidatedOrderStatusMessages &&
        assignment.ConsolidatedOrderStatus.ConsolidatedOrderStatusMessages
          .length > 0 && (
          <div
            className={`tv-workassignment__detail-sub-status mx-2 tv-workassignment__detail-sub-status--${assignment.ConsolidatedOrderStatus.ConsolidatedOrderStatusMessages[0].StatusMessageIdentifier}`}
          >
            {
              assignment.ConsolidatedOrderStatus
                .ConsolidatedOrderStatusMessages[0].StatusMessage
            }
          </div>
        )}
    </>
  );
  const orderDateTimeRender = assignment => {
    const dateTimeFrom = moment(assignment.DatetimeFrom);
    const dateTimeTo = moment(assignment.DatetimeTo);
    let orderDateTimeStr = `${dateTimeFrom.format(
      'HH:mm',
    )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;
    if (!dateTimeFrom.isSame(dateTimeTo, 'day')) {
      orderDateTimeStr = `${dateTimeFrom.format(
        'HH:mm, DD MMMM',
      )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;
    }
    return orderDateTimeStr;
  };
  const ModalHeaderRender = () => (
    <div
      className={`tv-workassignment__modal-header row ml-0 ${
        isIE ? 'ie-fix-width' : ''
      }`}
    >
      <div className="tv-display-mobile-show d-flex d-md-none w-100 justify-content-center">
        <span className="pr-2"> Detaljer</span>
        <span>{assignment.OrderNumber}</span>
      </div>
      <div className="tv-display-mobile-hide d-none d-md-flex justify-content-between w-50">
        <span className="col-5 pl-0 ">
          {Translate({
            content: 'taskDetail.assignmentFullDetail',
          })}
        </span>
        <span className="secondary-heading tv-body-text">
          {assignment.OrderNumber}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        className="tv-workassignment__modal"
        onCloseClicked={() => {
          onHandleModal();
        }}
        onOverlayClick={() => {
          onHandleModal();
        }}
        isVisible={showModal}
        headerRender={<ModalHeaderRender />}
        headerText={Translate({
          content: 'taskDetail.assignmentFullDetail',
        })}
        ref={componentRef}
        ieOptionClassName={isIE ? 'ie-fix-height' : ''}
      >
        <div className="d-flex flex-column">
          {/* <div style={{ display: 'none' }}>
            <div id="print-content">
              <PrintableAssignmentDetail
                ref={componentRef}
                assignment={assignment}
                isShowInterpreterElement={isShowInterpreterElement}
                status={status}
              />
            </div>
          </div> */}
          {secondaryMessage}
          <AssignmentStatus
            className="tv-workassignment__modal-status align-items-md-center d-flex justify-content-between ml-0 px-2 "
            status={status.name}
          >
            <div>
              {`${Translate({
                content: 'singleAssignment.bookingInformation',
              })}`}
            </div>
          </AssignmentStatus>
          <div className="d-flex flex-column  tv-workassignment__modal-detail-container">
            <div className="w-100 ml-2">
              <InformationItem
                label="Datum"
                content={orderDateTimeRender(assignment)}
              />
              <InformationItem
                labelled={
                  assignment.SkillSubstituteIdentifier &&
                  !assignment.Resource.IsBasedOnSkillSubstitute &&
                  assignment.OrderStatus !== 'available'
                }
                label={Translate({
                  content: 'report.skill',
                })}
                content={assignment.Skill}
              />
              {assignment.SkillSubstituteIdentifier && (
                <InformationItem
                  labelled={
                    assignment.Resource.IsBasedOnSkillSubstitute &&
                    assignment.OrderStatus !== 'available'
                  }
                  label={Translate({
                    content: 'report.substituteSkill',
                  })}
                  content={assignment.SkillSubstitute}
                />
              )}
              <InformationItem
                label="Bokning"
                content={assignment.ArticleName}
              />
              {assignment.ServiceAlternatives &&
                altService &&
                assignment.ServiceIdentifier ===
                  assignment.ServiceIdentifierOrdered && (
                  <InformationItem
                    label="Alternativt typ av tolk"
                    content={altService.ServiceName}
                  />
                )}
              {assignment.IsPerformedRemotely ? (
                <IfComponent
                  condition={
                    assignment.ArticleName.includes('Tele') &&
                    (assignment.OrderStatus === 'fullfilled' ||
                      assignment.OrderStatus === 'accepted')
                  }
                  whenTrue={
                    <InformationItem
                      label={Translate({ content: 'taskDetail.phoneNumber' })}
                      content={
                        assignment.MeetingDetails.MeetingPhoneNumber ||
                        assignment.PhoneNumberOrder ||
                        assignment.ContactPerson
                          .ContactPersonMobilePhoneNumber ||
                        assignment.ContactPerson
                          .ContactPersonDirectPhoneNumber ||
                        '-'
                      }
                    />
                  }
                  whenFalse={
                    <>
                      {(assignment.MeetingDetails.MeetingPhoneNumber ||
                        assignment.PhoneNumberOrder) && (
                        <InformationItem
                          label={Translate({
                            content: 'taskDetail.phoneNumber',
                          })}
                          content={
                            assignment.MeetingDetails.MeetingPhoneNumber ||
                            assignment.PhoneNumberOrder ||
                            '-'
                          }
                        />
                      )}
                      {assignment.MeetingDetails.DigitalMeetingPlatformName && (
                        <InformationItem
                          label={Translate({ content: 'taskDetail.platform' })}
                          content={
                            assignment.MeetingDetails.DigitalMeetingPlatformName
                          }
                        />
                      )}

                      {assignment.MeetingDetails.MeetingUrl && (
                        <InformationItem
                          label={Translate({ content: 'taskDetail.videoLink' })}
                          content={assignment.MeetingDetails.MeetingUrl}
                        />
                      )}
                      {assignment.MeetingDetails.DoRequireBankIdVerification ===
                        'True' &&
                        assignment.MeetingDetails
                          .DoRequireBankIdVerification && (
                          <InformationItem
                            label={Translate({ content: 'taskDetail.bankId' })}
                            content={Translate({
                              content: 'taskDetail.require',
                            })}
                          />
                        )}
                      {assignment.MeetingDetails.MeetingPassCode && (
                        <InformationItem
                          label={Translate({ content: 'taskDetail.passcode' })}
                          content={assignment.MeetingDetails.MeetingPassCode}
                        />
                      )}
                    </>
                  }
                />
              ) : (
                <InformationItem
                  label={Translate({ content: 'taskDetail.location' })}
                  className={
                    assignment.IsDeviatingAddressPerforming ? 'red-text' : ''
                  }
                  content={
                    assignment.IsDeviatingAddressPerforming
                      ? `OBS! ${assignment.AddressPerformingEffective}`
                      : assignment.AddressPerformingEffective
                  }
                />
              )}
              {isShowInterpreterElement && (
                <>
                  <InformationItem
                    content={assignment.Resource.ResourceFullName}
                    label={Translate({
                      content: 'taskDetail.interpreterFullName',
                    })}
                  />
                  {assignment.Resource?.ResourceNumber && (
                    <InformationItem
                      content={assignment.Resource?.ResourceNumber}
                      label={'Tolknummer'}
                    />
                  )}
                  {assignment.Resource.ResourceGenderName && (
                    <InformationItem
                      content={assignment.Resource.ResourceGenderName}
                      label={'Tolkens kön'}
                    />
                  )}
                  {assignment.Resource?.ResourceCompetenceLevelName && (
                    <InformationItem
                      content={assignment.Resource?.ResourceCompetenceLevelName}
                      label={'Tolkens kompetens'}
                    />
                  )}
                </>
              )}
              <InformationItem
                content={
                  assignment.ContactPersonOrderer.ContactPersonOrdererName
                }
                label={Translate({
                  content: 'taskDetail.orderedPerson',
                })}
              />
            </div>
            {(assignment.GenderIdPreferred ||
              assignment.CompetenceLevel ||
              assignment.assignmentSubskill ||
              assignment.requestedInterpreter) && (
              <>
                <hr />
                <div className="w-100 ml-2">
                  <div className="tv-workassignment__modal-subheader">
                    {Translate({
                      content: 'singleAssignment.demandAndWishes',
                    })}
                  </div>
                  {assignment.GenderIdPreferred && (
                    <InformationItem
                      label={Translate({
                        content: 'taskDetail.gender',
                      })}
                      content={genderIdtoString(assignment.GenderIdPreferred)}
                      conditions={
                        assignment.GenderIdPreferred
                          ? assignment.DoRequireCorrectGender
                            ? 'Krävs'
                            : 'Önskemål'
                          : ''
                      }
                    />
                  )}
                  {assignment.CompetenceLevel && (
                    <InformationItem
                      label={Translate({
                        content: 'report.competence',
                      })}
                      content={
                        assignment.CompetenceLevel
                          ? assignment.CompetenceLevel
                          : 'Ej angivet'
                      }
                      conditions={
                        assignment.CompetenceLevel ? competencePreference : ''
                      }
                    />
                  )}{' '}
                  {assignment.assignmentSubskill ? (
                    <InformationItem
                      label="Dialekt"
                      content={assignment.assignmentSubskill}
                    />
                  ) : null}
                  {assignment.requestedInterpreter ? (
                    <InformationItem
                      label={Translate({
                        content: 'singleAssignment.requestedInterpreterLabel',
                      })}
                      content={assignment.requestedInterpreter}
                      conditions={requestedInterpreterPreferedLevelString(
                        assignment.PreferredResourceLevel,
                      )}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
          {((assignment.additionalRequirements &&
            assignment.additionalRequirements !== '') ||
            (assignment.CustomerReference &&
              assignment.CustomerReference !== '') ||
            (assignment.assignmentDescription &&
              assignment.assignmentDescription !== '')) && (
            <>
              <hr />
              <div className="w-100 ml-2">
                <div className="tv-workassignment__modal-subheader">
                  <Translate content="singleAssignment.extraBookingInformation" />
                </div>
                <div className="w-100 mt-2 mb-3">
                  <div className="mx-0 row">
                    <div className="col-12 pl-0 tv-workassignment__modal-info-label">
                      <Translate content="singleAssignment.additionalRequestedRequirment" />
                    </div>
                    <div className="col-12 ml-0 pl-0 text-wrap tv-workassignment__modal-info-content">
                      {assignment.additionalRequirements &&
                      assignment.additionalRequirements != '' ? (
                        <> {assignment.additionalRequirements}</>
                      ) : (
                        <Translate content="general.none" />
                      )}
                    </div>
                  </div>
                  <div className="mx-0 row">
                    <div className="col-12 pl-0 tv-workassignment__modal-info-label">
                      <Translate content="singleAssignment.yourReferenceNumber" />
                    </div>
                    <div className="col-12 ml-0 pl-0 text-wrap tv-workassignment__modal-info-content">
                      {assignment.CustomerReference &&
                      assignment.CustomerReference != '' ? (
                        <> {assignment.CustomerReference}</>
                      ) : (
                        <Translate content="general.none" />
                      )}
                    </div>
                  </div>
                  <div className="mx-0 row">
                    <div className="col-12 pl-0 tv-workassignment__modal-info-label">
                      <Translate content="taskDetail.infoForInterpreter" />
                    </div>
                    <div className="col-12 ml-0 pl-0 text-wrap tv-workassignment__modal-info-content">
                      {assignment.assignmentDescription &&
                      assignment.assignmentDescription != '' ? (
                        <>{assignment.assignmentDescription}</>
                      ) : (
                        <Translate content="general.none" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <hr />
          <div className="d-flex flex-column">
            <div className="tv-workassignment__detail-links ml-2 justify-content-between">
              <ReactToPrint
                trigger={() => (
                  <div className="td-block ml-auto tv-workassignment__detail-links-item">
                    {Translate({
                      content: 'reportQuality.printButton',
                    })}
                  </div>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div className="tv-workassignment__detail-buttons tv-display-flex">
              <TransvoiceButton
                type="line-primary"
                text={Translate({ content: 'general.close' })}
                onClick={onHandleModal}
                className="tv_assignment_detail__button"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AssignmentFullDetailed;

function InformationItem({
  label,
  content,
  conditions,
  className,
  labelled = false,
}) {
  return (
    <div className="row">
      <div
        className={`tv-workassignment__modal-info-label ${
          labelled ? 'labelled' : ''
        } col-4 col-lg-4 ${conditions ? 'mb-1' : ''}`}
      >
        {label}
      </div>
      <div
        className={`tv-workassignment__modal-info-content  ${
          labelled ? 'labelled' : ''
        } ${
          conditions ? 'col-5 mb-1  col-lg-3' : 'col-7  col-lg-7'
        } ${className}`}
        title={content}
      >
        {content}
      </div>
      {conditions && (
        <div className="tv-workassignment__modal-info-condition  col-6  col-lg-3">
          {conditions}
        </div>
      )}
    </div>
  );
}
