import React, { useEffect, useState } from 'react';
import { Translate, Modal, TransvoiceButton } from '../../../Share/components';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { getDateFormated } from '../../../Share/utils';
import { convertStringToDate } from '../../../Share/utils/dateUtil';

function AcoliAvailableModal({
  isVisible,
  hideAcoliModal,
  displayAcoliSuccessModal,
  assignment,
  setSelectedSlot,
}) {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [showFirstStep, setShowFirstStep] = useState(true);
  const [showSecondStep, setShowSecondStep] = useState(false);
  const {
    dateRange,
    typeOfAssignment,
    contactPerson,
    orderContactPerson,
    contactPersons,
    language,
  } = assignment;

  const filterContactPerson = contactPersons.filter(
    obj => obj.value === contactPerson,
  )[0];
  const previewContactPerson = filterContactPerson
    ? filterContactPerson.name
    : Translate({ content: 'general.none' });

  const filterContactOrderPerson = contactPersons.filter(
    obj => obj.value === orderContactPerson,
  )[0];
  const previewContactOrderPerson = filterContactOrderPerson
    ? filterContactOrderPerson.name
    : Translate({ content: 'general.none' });

  const previewOrderTime = sess => {
    let session = cloneDeep(sess);

    const beginningTime = moment({
      h: session.startTime.hours(),
      m: session.startTime.minutes(),
    });
    const endTime = moment({
      h: session.endTime.hours(),
      m: session.endTime.minutes(),
    });
    session.ranges.startDate = session.ranges.startDate.clone();
    session.ranges.endDate = session.ranges.endDate.clone();
    if (
      (beginningTime.isAfter(endTime) || endTime.isSame(beginningTime)) &&
      session.ranges.endDate.isSame(session.ranges.startDate, 'day')
    ) {
      session.ranges.endDate.add(1, 'day');
    }
    const startDate = getDateFormated(
      convertStringToDate(session.ranges.startDate),
      'DD MMMM',
    );
    const endDate = getDateFormated(
      convertStringToDate(session.ranges.endDate),
      'DD MMMM',
    );
    const startTimeStr = moment(session.startTime).format('HH:mm');
    const endTimeStr = moment(session.endTime).format('HH:mm');
    let orderDateTimeString = `${startTimeStr} - ${endTimeStr}, ${startDate}`;

    if (startDate !== endDate) {
      orderDateTimeString = `${startTimeStr}, ${startDate} - ${endTimeStr}, ${endDate}`;
    }
    return orderDateTimeString;
  };

  return (
    <Modal
      className="tv-alternativeTid__modal"
      isVisible={isVisible}
      headerRender={<></>}
      ieOptionClassName={'alternativeTid-holder'}
      onCloseClicked={() => {
        hideAcoliModal(false);
      }}
    >
      <div className="tv-modal__holder__custom-header">
        <h2>Tyvärr finns ingen ledig tolk för detta språk ditt valda datum</h2>
      </div>
      <div className="tv-alternativeTid__container">
        <div className="tv-alternativeTid__top-container">
          <div className="tv-alternativeTid__top-container__title">
            Bokningsinformation
          </div>
          <div className="tv-alternativeTid__top-container__list">
            <BookingInfoItem label="Språk" value={language.name} />
            <BookingInfoItem label="Bokning" value={typeOfAssignment.name} />
            <BookingInfoItem label="Tid" value={previewOrderTime(dateRange)} />
            <BookingInfoItem label="Tolk" value={previewContactPerson} />
            <BookingInfoItem label="" value="" />
            <BookingInfoItem
              label="Beställare"
              value={previewContactOrderPerson}
            />
          </div>
        </div>

        <div className="tv-alternativeTid__time-container">
          <div className="tv-alternativeTid__time-container__title">
            Vänligen välj alternativ tid
          </div>
          <div className="tv-alternativeTid__time-container__box">
            <i
              className="fa fa-chevron-circle-left arrow-back"
              aria-hidden="true"
              onClick={() => {
                if (showSecondStep) {
                  setShowFirstStep(true);
                  setShowSecondStep(false);
                }
              }}
              style={{ color: showSecondStep ? '#873bff' : 'lightgray' }}
            ></i>
            {/* Måndag 19 augusti 2024 */}
            {showFirstStep && !showSecondStep && (
              <div className="tv-alternativeTid__time-container__box-section">
                <div className="time-container__date">
                  Måndag 19 augusti 2024
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 1 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(1);
                    setSelectedSlot({
                      startDate: '2024-08-19 10:00:00',
                      endDate: '2024-08-19 11:00:00',
                    });
                  }}
                >
                  <div className="time-limit">10.00-11.00</div>
                  <div>
                    <img src="/images/plus.png" alt="plus-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 2 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(2);
                    setSelectedSlot({
                      startDate: '2024-08-19 13:00:00',
                      endDate: '2024-08-19 14:00:00',
                    });
                  }}
                >
                  <div className="time-limit">13.00-14.00</div>
                  <div>
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
              </div>
            )}
            {/* Tisdag 20 augusti 2024 */}
            {showFirstStep && !showSecondStep && (
              <div className="tv-alternativeTid__time-container__box-section">
                <div className="time-container__date">
                  Tisdag 20 augusti 2024
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 3 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(3);
                    setSelectedSlot({
                      startDate: '2024-08-20 10:00:00',
                      endDate: '2024-08-20 11:00:00',
                    });
                  }}
                >
                  <div className="time-limit">10.00-11.00</div>
                  <div>
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 4 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(4);
                    setSelectedSlot({
                      startDate: '2024-08-20 14:00:00',
                      endDate: '2024-08-20 15:00:00',
                    });
                  }}
                >
                  <div className="time-limit">14.00-15.00</div>
                  <div>
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 5 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(5);
                    setSelectedSlot({
                      startDate: '2024-08-20 15:00:00',
                      endDate: '2024-08-20 16:00:00',
                    });
                  }}
                >
                  <div className="time-limit">15.00-16.00</div>
                  <div>
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
              </div>
            )}
            {/* Onsdag 21 augusti 2024 */}
            {!showFirstStep && showSecondStep && (
              <div className="tv-alternativeTid__time-container__box-section">
                <div className="time-container__date">
                  Onsdag 21 augusti 2024
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 6 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(6);
                    setSelectedSlot({
                      startDate: '2024-08-21 10:00:00',
                      endDate: '2024-08-21 11:00:00',
                    });
                  }}
                >
                  <div className="time-limit">10.00-11.00</div>
                  <div>
                    <img src="/images/plus.png" alt="plus-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 7 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(7);
                    setSelectedSlot({
                      startDate: '2024-08-21 13:00:00',
                      endDate: '2024-08-21 14:00:00',
                    });
                  }}
                >
                  <div className="time-limit">13.00-14.00</div>
                  <div>
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
              </div>
            )}
            {/* Torsdag 22 augusti 2024 */}
            {!showFirstStep && showSecondStep && (
              <div className="tv-alternativeTid__time-container__box-section">
                <div className="time-container__date">
                  Torsdag 22 augusti 2024
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 8 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(8);
                    setSelectedSlot({
                      startDate: '2024-08-22 10:00:00',
                      endDate: '2024-08-22 11:00:00',
                    });
                  }}
                >
                  <div className="time-limit">10.00-11.00</div>
                  <div>
                    <img src="/images/person.png" alt="person-icon" />
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 9 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(9);
                    setSelectedSlot({
                      startDate: '2024-08-22 14:00:00',
                      endDate: '2024-08-22 15:00:00',
                    });
                  }}
                >
                  <div className="time-limit">14.00-15.00</div>
                  <div>
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
                <div
                  className={`time-slot ${
                    selectedTimeSlot === 10 ? 'selected' : null
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(10);
                    setSelectedSlot({
                      startDate: '2024-08-22 15:00:00',
                      endDate: '2024-08-22 16:00:00',
                    });
                  }}
                >
                  <div className="time-limit">15.00-16.00</div>
                  <div>
                    <img src="/images/person.png" alt="person-icon" />
                  </div>
                </div>
              </div>
            )}
            <i
              class="fa fa-chevron-circle-right arrow-forward"
              aria-hidden="true"
              onClick={() => {
                if (showFirstStep) {
                  setShowFirstStep(false);
                  setShowSecondStep(true);
                }
              }}
              style={{ color: showFirstStep ? '#873bff' : 'lightgray' }}
            ></i>
          </div>
        </div>
        <div className="tv-alternativeTid__actions">
          <TransvoiceButton
            className="cancel-button"
            text="Tillbaka"
            onClick={() => {
              hideAcoliModal(false);
            }}
          />
          <TransvoiceButton
            text="Bekräfta ny tid"
            className={`submit-button ${
              selectedTimeSlot !== null ? 'continue' : null
            }`}
            onClick={() => {
              if (selectedTimeSlot !== null) {
                displayAcoliSuccessModal(true);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

const BookingInfoItem = ({ label, value }) => {
  return (
    <div className="tv-alternativeTid__top-container__list-item">
      {label && <div>{label}: &nbsp;</div>}
      {!label && <div></div>}
      <div>{value}</div>
    </div>
  );
};

export default AcoliAvailableModal;
