import React from 'react';
import { Translate, Modal, TransvoiceButton } from '../../../Share/components';
import moment from 'moment';

function AcoliSuccessModal({
  isVisible,
  onClose,
  onContinueBooking,
  assignment,
  selectedTimeSlot,
}) {
  const {
    dateRange,
    typeOfAssignment,
    contactPerson,
    orderContactPerson,
    contactPersons,
    language,
  } = assignment;

  const orderDateTimeRender = slot => {
    const { startDate, endDate } = slot;
    // const range = slots.filter(i => i.slotNo === slot);
    const dateTimeFrom = moment(startDate);
    const dateTimeTo = moment(endDate);
    let orderDateTimeStr = `${dateTimeFrom.format(
      'HH:mm',
    )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;

    if (!dateTimeFrom.isSame(dateTimeTo, 'day')) {
      orderDateTimeStr = `${dateTimeFrom.format(
        'HH:mm, DD MMMM',
      )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;
    }
    return orderDateTimeStr;
  };

  const filterContactPerson = contactPersons.filter(
    obj => obj.value === contactPerson,
  )[0];
  const previewContactPerson = filterContactPerson
    ? filterContactPerson.name
    : Translate({ content: 'general.none' });

  const filterContactOrderPerson = contactPersons.filter(
    obj => obj.value === orderContactPerson,
  )[0];
  const previewContactOrderPerson = filterContactOrderPerson
    ? filterContactOrderPerson.name
    : Translate({ content: 'general.none' });

  return (
    <Modal
      className="tv-alternativeTid__modal"
      isVisible={isVisible}
      headerRender={<></>}
      ieOptionClassName={'acoliSuccess-holder'}
      onCloseClicked={onClose}
    >
      <div className="tv-modal__holder__custom-header">
        <h2>Din bokning är skickad!</h2>
      </div>
      <div className="tv-alternativeTid__container">
        <div className="tv-alternativeTid__logo">
          <img src="/images/acoli-success.png" alt="acoli-success-logo" />
        </div>
        <div className="tv-alternativeTid__top-container" style={{ border: 0 }}>
          <div className="tv-alternativeTid__top-container__main-title">
            Ditt nya bokningsnummer är{' '}
            {language.name === 'Hausa' ? 'FL2874' : 'FL2895'}
          </div>
          <div className="tv-alternativeTid__top-container__title">
            Bokningsinformation
          </div>
          <div className="tv-alternativeTid__top-container__list">
            <BookingInfoItem label="Språk" value={language.name} />
            <BookingInfoItem label="Bokning" value={typeOfAssignment.name} />
            <BookingInfoItem
              label="Tid"
              value={orderDateTimeRender(selectedTimeSlot)}
            />
            <BookingInfoItem label="Tolk" value={previewContactPerson} />
            <BookingInfoItem label="" value="" />
            <BookingInfoItem
              label="Beställare"
              value={previewContactOrderPerson}
            />
          </div>
        </div>
        <div
          className="tv-alternativeTid__actions"
          style={{ paddingInline: '35px' }}
        >
          <TransvoiceButton
            className="cancel-button"
            text="Fortsätt boka"
            onClick={onContinueBooking}
          />
          <TransvoiceButton
            buttonType="submit"
            type="secondary"
            text="Klar"
            className="close-button"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
}

const BookingInfoItem = ({ label, value }) => {
  return (
    <div className="tv-alternativeTid__top-container__list-item">
      {label && <div>{label}: &nbsp;</div>}
      {!label && <div></div>}
      <div>{value}</div>
    </div>
  );
};

export default AcoliSuccessModal;

const slots = [
  {
    slotNo: 1,
    time: {
      startDate: '2024-02-07 10:00:00',
      endDate: '2024-02-07 11:00:00',
    },
  },
  {
    slotNo: 2,
    time: {
      startDate: '2024-02-07 13:00:00',
      endDate: '2024-02-07 14:00:00',
    },
  },
  {
    slotNo: 3,
    time: {
      startDate: '2024-02-09 10:00:00',
      endDate: '2024-02-09 11:00:00',
    },
  },
  {
    slotNo: 4,
    time: {
      startDate: '2024-02-09 13:00:00',
      endDate: '2024-02-09 14:00:00',
    },
  },
  {
    slotNo: 5,
    time: {
      startDate: '2024-02-09 15:00:00',
      endDate: '2024-02-09 16:00:00',
    },
  },
];
