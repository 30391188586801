import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { isEqual, clone } from 'lodash/core';
import { toast } from 'react-toastify';
import * as api from '../api';
import { showLoadingOverlay, hiddenLoadingOverlay } from './app';
import { timeJob, statusMapping, subStatus } from '../../Share/constants';
import { transvoiceLog, logApiLevel } from '../../Share/utils';

import history from '../../Share/utils/history';
import {
  UPDATE_MY_ASSIGNMENT_LIST,
  UPDATE_MY_SEARCH_ASSIGNMENT_LIST,
  UPDATE_MY_ASSIGNMENT_FILTERS,
  SEARCH_ORDER,
  RESET_MY_ASSIGNMENT_FILTER,
  UPDATE_NEW_ASSIGNMENTS,
  RESET_JOBTIMER,
  UPDATE_SUBMITTED_ASSIGNMENT,
  UPDATE_JOBTIMER_MY_ASSIGNMENT,
  UPDATE_ASSIGNMENTS_RATINGS,
  UPDATE_ASSIGNMENT_SUMMARY,
  UPDATE_ASSIGNMENT_DATE_RANGE,
  UPDATE_CONTACT_PERSONS,
  UPDATE_RATING_REASONS,
  GET_AVAILABLE_RESOURCE_AUTO_ASSIGN,
  CREATE_NEW_AUTO_ASSIGN_ORDER,
  CREATE_NEW_AUTO_ASSIGN_ORDER_ERROR,
  GET_AUTO_ASSIGN_OPENING_HOURS,
  GET_AVAILABLE_RESOURCE_AUTO_ASSIGN_PENDING,
} from './ActionTypes';
import {
  checkTimeForCalled,
  currentDate,
  convertStringToDate,
} from '../../Share/utils/dateUtil';
import { fetchContactPersons } from './user';
import { Translate } from '../../Share/components';
import { broadCast } from './eventBus';

export function updateMyAssignmentFilters(filterType, value) {
  return dispatch =>
    dispatch({
      type: UPDATE_MY_ASSIGNMENT_FILTERS,
      filterType,
      value,
    });
}

export function onUpdateSearchByOrder(searchOrder) {
  return dispatch =>
    dispatch({
      type: SEARCH_ORDER,
      searchOrder,
    });
}

export const onResetMyAssignment = () => ({
  type: RESET_MY_ASSIGNMENT_FILTER,
});

export function searchWorkAssignments(
  statusItem,
  datetimeFrom,
  datetimeTo,
  order = '',
  isRefresh = false,
  forSearch = false,
  orderIdentifier = null,
) {
  return (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const { timeMyAssignment } = state.jobTimer;

    const dispatchResult = assignmentListUnGrouped => {
      const assignmentList = [];
      const assignmentGrouped = groupBy(
        assignmentListUnGrouped,
        value => value.groupNumber,
      );
      assignmentListUnGrouped.forEach(item => {
        Object.keys(assignmentGrouped).forEach(key => {
          if (isEqual(item, assignmentGrouped[key][0])) {
            assignmentList.push(...assignmentGrouped[key]);
          }
        });
      });

      if (forSearch) {
        dispatch({
          type: UPDATE_MY_SEARCH_ASSIGNMENT_LIST,
          assignmentList,
        });
      } else {
        dispatch({
          type: UPDATE_MY_ASSIGNMENT_LIST,
          assignmentList,
        });
      }
    };

    if (
      checkTimeForCalled(timeMyAssignment, timeJob.JOB_TIMER_MY_ASSIGNMENT) ||
      isRefresh
    ) {
      dispatch(showLoadingOverlay());
      api
        .searchOrders(
          token,
          datetimeFrom,
          datetimeTo,
          '',
          order,
          orderIdentifier,
        )
        .then(response => {
          if (response.data && !response.data.Errors) {
            if (response.data.Orders === null) {
              if (forSearch) {
                if (orderIdentifier) {
                  dispatch({
                    type: UPDATE_MY_SEARCH_ASSIGNMENT_LIST,
                    assignmentList: [],
                  });
                }
              } else {
                dispatch({
                  type: UPDATE_MY_ASSIGNMENT_LIST,
                  assignmentList: [],
                });
              }
            } else {
              response.data.Orders.sort((a, b) => {
                try {
                  // sort by DatetimeOrderFrom
                  const aFrom = convertStringToDate(a.DatetimeFrom);
                  const bFrom = convertStringToDate(b.DatetimeFrom);
                  if (aFrom.isAfter(bFrom)) {
                    return 1;
                  }

                  return -1;
                } catch (error) {
                  return 0;
                }
              });
              const orders = response.data.Orders.map(item => {
                const groupNumber =
                  item.OrderGroup.OrderGroupIdentifier || item.OrderIdentifier;
                const OrderStatus = item.ConsolidatedOrderStatus
                  ?.StatusIdentifier
                  ? statusMapping[item.ConsolidatedOrderStatus.StatusIdentifier]
                  : '';
                const subStatusIdentifier =
                  item.ConsolidatedOrderStatus
                    ?.ConsolidatedOrderStatusMessages &&
                  item.ConsolidatedOrderStatus.ConsolidatedOrderStatusMessages
                    .length > 0
                    ? item.ConsolidatedOrderStatus
                        .ConsolidatedOrderStatusMessages[0]
                        .StatusMessageIdentifier
                    : subStatus.unknownStatus;
                return {
                  ...item,
                  groupNumber,
                  OrderStatus,
                  subStatusIdentifier,
                };
              });
              if (orderIdentifier && forSearch) {
                dispatchResult([
                  ...orders,
                  ...state.workAssignment.searchedAssignmentList,
                ]);
              } else {
                dispatchResult(orders);
              }
              // update job timer
              const timeAssignment = currentDate();
              dispatch({
                type: UPDATE_JOBTIMER_MY_ASSIGNMENT,
                timeMyAssignment: isRefresh
                  ? timeAssignment.subtract(1, 'hours')
                  : timeAssignment,
              });
            }
          } else {
            transvoiceLog(response.data.Errors);
          }
        })
        .catch(err => {
          transvoiceLog(err);
        })
        .finally(() => {
          dispatch(hiddenLoadingOverlay());
        });
    }
  };
}

export function updateAssignmentSummary(datetimeFrom, datetimeTo, order = '') {
  return (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const { WorkassignmentRange } = state.workAssignment;

    dispatch({
      type: UPDATE_ASSIGNMENT_DATE_RANGE,
      WorkassignmentRange: {
        from: datetimeFrom,
        to: datetimeTo,
      },
    });

    const dispatchResult = assignmentListUnGrouped => {
      const assignmentSummaryList = [];
      const assignmentGrouped = groupBy(
        assignmentListUnGrouped,
        value => value.groupNumber,
      );
      assignmentListUnGrouped.forEach(item => {
        Object.keys(assignmentGrouped).forEach(key => {
          if (isEqual(item, assignmentGrouped[key][0])) {
            assignmentSummaryList.push(...assignmentGrouped[key]);
          }
        });
      });
      dispatch({
        type: UPDATE_ASSIGNMENT_SUMMARY,
        assignmentSummaryList,
      });
    };
    if (
      WorkassignmentRange.from.substring(0, 10) !=
        datetimeFrom.substring(0, 10) ||
      WorkassignmentRange.to.substring(0, 10) != datetimeTo.substring(0, 10)
    ) {
      dispatch(showLoadingOverlay());
      api
        .searchOrders(token, datetimeFrom, datetimeTo, order)
        .then(response => {
          if (response.data && !response.data.Errors) {
            if (response.data.Orders === null) {
              dispatch({
                type: UPDATE_ASSIGNMENT_SUMMARY,
                assignmentSummaryList: [],
              });
            } else {
              const orders = response.data.Orders.map(item => {
                const groupNumber =
                  item.OrderGroup.OrderGroupIdentifier || item.OrderIdentifier;
                const OrderStatus = item.ConsolidatedOrderStatus
                  ?.StatusIdentifier
                  ? statusMapping[item.ConsolidatedOrderStatus.StatusIdentifier]
                  : '';
                const subStatusIdentifier =
                  item.ConsolidatedOrderStatus
                    ?.ConsolidatedOrderStatusMessages &&
                  item.ConsolidatedOrderStatus.ConsolidatedOrderStatusMessages
                    .length > 0
                    ? item.ConsolidatedOrderStatus
                        .ConsolidatedOrderStatusMessages[0]
                        .StatusMessageIdentifier
                    : subStatus.unknownStatus;
                return {
                  ...item,
                  groupNumber,
                  OrderStatus,
                  subStatusIdentifier,
                };
              });
              dispatchResult(orders);
            }
          } else {
            transvoiceLog(response.data.Errors);
          }
        })
        .catch(err => {
          transvoiceLog(err);
        })
        .finally(() => {
          dispatch(hiddenLoadingOverlay());
        });
    }
  };
}

export function onUpdateNewAssignmentsTemp(assignments) {
  return dispatch => {
    dispatch({
      type: UPDATE_NEW_ASSIGNMENTS,
      assignmentTemp: assignments,
    });
  };
}

function formatterOrder(assignment, contactList) {
  const AttactmentData = assignment.files.map(item => ({
    Attachment: item.data,
    AttachmentContentType: item.type,
    AttachmentFilename: item.name,
    Description: '',
  }));
  let CompetenceIdentifier;
  if (assignment.certificationLevels !== 'none') {
    if (
      assignment.certificationLevels &&
      assignment.certificationLevels.value != ''
    ) {
      CompetenceIdentifier = assignment.certificationLevels.value;
    } else {
      CompetenceIdentifier = undefined;
    }
  }

  assignment.serialDateRanges = [assignment.dateRange];
  const orderOccasions = assignment.serialDateRanges.map(item => {
    // Checking the time - is from next day or current day
    const beginningTime = moment({
      h: item.startTime.hours(),
      s: item.startTime.minutes(),
    });
    const endTime = moment({
      h: item.endTime.hours(),
      s: item.endTime.minutes(),
    });

    if (endTime.isBefore(beginningTime)) {
      item.ranges.endDate = moment(item.ranges.startDate);
      item.ranges.endDate.add(1, 'day');
    } else if (endTime.isSame(beginningTime)) {
      item.ranges.endDate = moment(item.ranges.startDate);
      item.ranges.endDate.add(1, 'day');
    } else {
      item.ranges.endDate = item.ranges.startDate;
    }

    return {
      DatetimeFrom: `${item.ranges.startDate.format(
        'YYYY-MM-DD',
      )} ${item.startTime.format('HH:mm:ss')}`,
      DatetimeTo: `${item.ranges.endDate.format(
        'YYYY-MM-DD',
      )} ${item.endTime.format('HH:mm:ss')}`,
      ServiceIdentifier: assignment.typeOfAssignment.value,
    };
  });
  // const DoRequireSameResourceForAllWorkAssignments = (orderOccasions.length > 1);
  const DepartmentPerforming = null;
  // `${
  //   assignment.district === '' ? '' : `${assignment.district}, `
  // }${assignment.addressLine}, ${assignment.postalCode}, ${assignment.city}`;
  let genderValue;
  if (assignment.genderValue) {
    genderValue = parseInt(assignment.genderValue.value, 10);
  }
  const DoRequireCorrectGender = assignment.genderRequirement;
  const GenderIdPreferred = genderValue || null;
  const address = assignment.isAddressEdited
    ? {
        AddressRow1: assignment.addressLine,
        CareOf: assignment.district,
        City: assignment.city,
        IsHomeAddress: assignment.isHomeAddress,
        PostalCode: Number(assignment.postalCode),
      }
    : undefined;

  const newContactPersonRequiredInfo = {
    ContactPersonIdentifier: assignment.contactPerson
      ? assignment.contactPerson
      : '',
    PhoneNumberDirect: assignment.contactDirectNumber
      ? assignment.contactDirectNumber
      : '',
    PhoneNumberMobile: assignment.contactNumber ? assignment.contactNumber : '',
    Email: assignment.contactEmail ? assignment.contactEmail : '',
  };

  const newOrderContactPersonRequiredInfo = {
    ContactPersonIdentifier: assignment.orderContactPerson
      ? assignment.orderContactPerson
      : '',
    PhoneNumberDirect: assignment.orderContactDirectNumber
      ? assignment.orderContactDirectNumber
      : '',
    PhoneNumberMobile: assignment.orderContactNumber
      ? assignment.orderContactNumber
      : '',
    Email: assignment.orderEmail ? assignment.orderEmail : '',
  };

  const contactPersonComparer = (contactPerson1, contactPerson2) => {
    const contactPersonModelMappers = [
      'FirstName',
      'LastName',
      'PhoneNumberDirect',
      'PhoneNumberMobile',
      'Title',
      'Email',
    ];
    let isMatch = true;
    contactPersonModelMappers
      .filter(mapper => contactPerson1[mapper] !== undefined)
      .forEach(mapper => {
        if (contactPerson1[mapper] !== contactPerson2[mapper]) {
          isMatch = false;
        }
      });
    return isMatch;
  };

  const createContactPersonData = (currentContactPerson, newContactPerson) => ({
    ContactPersonIdentifier: currentContactPerson.ContactPersonIdentifier,
  });
  const filteredContactFromData = assignment.contactPersons.filter(
    obj => obj.value === assignment.contactPerson,
  )[0];

  const filteredContactFromState = contactList.filter(obj =>
    contactPersonComparer(obj, filteredContactFromData),
  );
  const contactPerson =
    filteredContactFromState.length > 0
      ? filteredContactFromState[0]
      : filteredContactFromData;

  const ContactPersonUponInterpretationRequestData = createContactPersonData(
    contactPerson,
    newContactPersonRequiredInfo,
  );

  const filteredOrderContactFromData = assignment.contactPersons.filter(
    obj => obj.value === assignment.orderContactPerson,
  )[0];

  const filteredOrderContactFromState = contactList.filter(obj =>
    contactPersonComparer(obj, filteredOrderContactFromData),
  );

  const orderContactPerson =
    filteredOrderContactFromState.length > 0
      ? filteredOrderContactFromState[0]
      : filteredOrderContactFromData;

  const ContactPersonOrdererRequestData = createContactPersonData(
    orderContactPerson,
    newOrderContactPersonRequiredInfo,
  );

  const filterInvoiceReceiver = assignment.services.filter(
    obj => obj.ServiceIdentifier === assignment.typeOfAssignment,
  )[0];

  const InvoiceReceivers = filterInvoiceReceiver
    ? filterInvoiceReceiver.InvoiceReceivers
    : '';

  const InvoiceReceiverIdentifier = InvoiceReceivers
    ? InvoiceReceivers[0].InvoiceReceiverIdentifier
    : '';

  const isAddNewContact = !!(
    !contactPerson.ContactPersonIdentifier ||
    !orderContactPerson.ContactPersonIdentifier
  );

  const PreferredResourceCustomer =
    assignment.requestedInterpreter && assignment.requestedInterpreter != ''
      ? assignment.requestedInterpreter.replace(/(\|){2}/g, '')
      : null;
  const PreferredResourceLevel =
    PreferredResourceCustomer && assignment.requestedInterpreterPreferedLevel
      ? parseInt(assignment.requestedInterpreterPreferedLevel)
      : null;
  const NotesResource = assignment.assignmentDescription
    ? assignment.assignmentDescription
    : '';
  const AdditionalRequirements = `${
    assignment.additionalRequirements ? assignment.additionalRequirements : ''
  }`;

  const isOrderGroupRequestData =
    assignment.OrderGroupRequestData !== null &&
    assignment.OrderGroupRequestData !== undefined;

  if (
    orderOccasions.length > 1 &&
    isOrderGroupRequestData &&
    assignment.OrderGroupRequestData
      .DoRequireSameResourceForAllWorkAssignments === true
  ) {
    assignment.OrderGroupRequestData = {
      Description: '',
      DoRequireSameResourceForAllWorkAssignments: true,
    };
    assignment.DoRequireSameResourceForAllWorkAssignments = true;
  } else {
    assignment.OrderGroupRequestData = null;
    assignment.DoRequireSameResourceForAllWorkAssignments = false;
  }

  const AlternativeServices =
    assignment.alternativeTypeOfAssignment &&
    assignment.alternativeTypeOfAssignment.value
      ? [
          {
            ServiceIdentifier: assignment.alternativeTypeOfAssignment.value,
            SortOrder: 1,
          },
        ]
      : null;

  return {
    ...assignment,
    AttactmentData,
    orderOccasions,

    // DoRequireSameResourceForAllWorkAssignments,
    DoRequireCorrectGender,
    address,
    ContactPersonOrdererRequestData,
    ContactPersonUponInterpretationRequestData,
    InvoiceReceiverIdentifier,
    GenderIdPreferred,
    DepartmentPerforming,
    PreferredResourceCustomer,
    PreferredResourceLevel,
    CompetenceIdentifier,
    isAddNewContact,
    NotesResource,
    AdditionalRequirements,
    SkillIdentifier: assignment.language.value,
    SkillSubstituteIdentifier: assignment.alternativeLanguage.value,
    DigitalMeetingPlatformIdentifier: assignment.meetingPlatform.value,
    DoRequireBankIdVerification: assignment.isBankIDRequired,
    MeetingPassCode: assignment.meetingPassCode,
    MeetingPhoneNumber: assignment.phoneNumber,
    MeetingUrl: assignment.meetingLink,
    AlternativeServices,
  };
}

export function updateAssignmentTemp() {
  return dispatch => {
    dispatch({
      type: UPDATE_NEW_ASSIGNMENTS,
      assignmentTemp: [],
    });
  };
}

export function createNewOrder(assignment) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const { contactPersons } = getState().user;
    const formatedAssignment = formatterOrder(assignment, contactPersons);
    try {
      dispatch(showLoadingOverlay());
      const response = await api.createNewOrder(token, formatedAssignment);
      if (response.data && !response.data.Errors) {
        const timeAssignment = currentDate();
        dispatch({
          type: RESET_JOBTIMER,
        });

        dispatch({
          type: UPDATE_JOBTIMER_MY_ASSIGNMENT,
          timeMyAssignment: timeAssignment.subtract(1, 'hours'),
        });
        broadCast('SuccessFullOrderCreation', {
          isBasic: assignment.isBasic,
          assignmentDetails: response.data.ReturnedOrder.map(r => ({
            language: formatedAssignment.languages.find(
              l => l.SkillIdentifier === formatedAssignment.language.value,
            ),
            typeOfAssignment: formatedAssignment.services.find(
              s =>
                s.ServiceIdentifier ===
                formatedAssignment.typeOfAssignment.value,
            ),
            dateTimeOrderTo: r.DateTimeOrderTo,
            dateTimeOrderFrom: r.DateTimeOrderFrom,
            orderNumber: r.OrderNumber,
            isNewOrder: false,
          })),
        });

        dispatch({
          type: UPDATE_CONTACT_PERSONS,
          contactPersons: [],
        });

        dispatch({
          type: UPDATE_NEW_ASSIGNMENTS,
          assignmentTemp: [],
        });
      } else {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};
        throw new Error(
          Translate({
            content: 'error.createNewOrderError',
            params: {
              content: JSON.stringify(errorResponse),
            },
          }),
        );
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function updateOrder(assignment) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const { contactPersons } = getState().user;
    const formatedAssignment = formatterOrder(assignment, contactPersons);
    try {
      dispatch(showLoadingOverlay());
      const response = await api.updateOrder(token, formatedAssignment);
      if (response.data && !response.data.Errors) {
        const updatedOrder = response.data?.UpdatedOrder;
        broadCast('SuccessFullOrderCreation', {
          isBasic: assignment.isBasic,
          assignmentDetails: [
            {
              language: formatedAssignment.languages.find(
                l => l.SkillIdentifier === formatedAssignment.language.value,
              ),
              typeOfAssignment: formatedAssignment.services.find(
                s =>
                  s.ServiceIdentifier ===
                  formatedAssignment.typeOfAssignment.value,
              ),
              dateTimeOrderTo: updatedOrder.DateTimeOrderTo,
              dateTimeOrderFrom: updatedOrder.DateTimeOrderFrom,
              orderNumber: updatedOrder.OrderNumber,
              isNewOrder: assignment.OrderNumber !== updatedOrder.OrderNumber,
            },
          ],
        });
      } else {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};
        throw new Error(
          Translate({
            content: 'error.createNewOrderError',
            params: {
              content: JSON.stringify(errorResponse),
            },
          }),
        );
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function createMultiNewOrder(assignments) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const errOrder = [];
    const submittedOrder = [];
    try {
      dispatch(showLoadingOverlay());
      // eslint-disable-next-line no-plusplus
      const AssignmentsToPassingToThankyou = [];
      for (let index = 0; index < assignments.length; index++) {
        const { contactPersons } = getState().user;
        const formatedOrder = formatterOrder(
          assignments[index].form,
          contactPersons,
        );
        try {
          const response = !assignments[index].isSubmitted
            ? // eslint-disable-next-line no-await-in-loop
              await api.createNewOrder(token, formatedOrder)
            : [];

          if (response.data && response.data.Errors) {
            throw response;
          } else {
            AssignmentsToPassingToThankyou.push({
              language: assignments[index].form.languages.find(
                l => l.SkillIdentifier === assignments[index].form.language,
              ),
              typeOfAssignment: assignments[index].form.services.find(
                s =>
                  s.ServiceIdentifier ===
                  assignments[index].form.typeOfAssignment,
              ),
              dateTimeOrderTo: response.data.ReturnedOrder[0].DateTimeOrderTo,
              dateTimeOrderFrom:
                response.data.ReturnedOrder[0].DateTimeOrderFrom,
              orderNumber: response.data.ReturnedOrder[0].OrderNumber,
            });
            submittedOrder.push(index);
            if (formatedOrder.isAddNewContact) {
              // eslint-disable-next-line no-await-in-loop
              await dispatch(fetchContactPersons());
            }
          }
        } catch (error) {
          errOrder.push(index);
        }
      }
      if (errOrder.length === 0) {
        const timeAssignment = currentDate();
        dispatch({
          type: RESET_JOBTIMER,
        });
        dispatch({
          type: UPDATE_NEW_ASSIGNMENTS,
          assignmentTemp: [],
        });
        dispatch({
          type: UPDATE_JOBTIMER_MY_ASSIGNMENT,
          timeMyAssignment: timeAssignment.subtract(1, 'hours'),
        });
        dispatch({
          type: UPDATE_CONTACT_PERSONS,
          contactPersons: [],
        });

        history.push({
          pathname: '/thank-you',
          state: {
            detail: Translate({
              content: 'thankForSubmitting.thanksForSubmittedOrder',
            }),
            assignmentDetails: AssignmentsToPassingToThankyou,
          },
        });
      } else {
        submittedOrder.forEach(item => {
          assignments.splice(item, 1);
        });
        const updatedAssignment = clone(assignments);
        dispatch({
          type: UPDATE_SUBMITTED_ASSIGNMENT,
          assignmentTemp: updatedAssignment,
        });
        toast.error(
          `${Translate({
            content: 'error.createMultipleOrderError',
          })}: ${errOrder.length}`,
        );
      }
    } catch (error) {
      transvoiceLog(error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function cancelOrder(
  reasonIdentifier,
  assignmentIdentifier,
  assignmentDetail,
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { token } = state.authentication;
      dispatch(showLoadingOverlay());
      const response = await api.cancelOrder(
        token,
        reasonIdentifier,
        assignmentIdentifier,
      );
      if (response.data && !response.data.Errors) {
        const timeAssignment = currentDate();
        dispatch({
          type: RESET_JOBTIMER,
        });
        dispatch({
          type: UPDATE_JOBTIMER_MY_ASSIGNMENT,
          timeMyAssignment: timeAssignment.subtract(1, 'hours'),
        });
        broadCast('SuccessFullCancellOrder', {
          OrderIdentifier: assignmentIdentifier,
        });
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

const autoAssignPending = pending => {
  return async dispatch => {
    dispatch({
      type: GET_AVAILABLE_RESOURCE_AUTO_ASSIGN_PENDING,
      pending,
    });
  };
};

export function availableResourceAutoAssign(
  ServiceIdentifier,
  SkillIdentifier,
  TimeOrderFrom,
  TimeOrderTo,
  sessionIdentifier,
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { token } = state.authentication;
      dispatch(autoAssignPending(true));
      const response = await api.availableResourceAutoAssign(
        token,
        ServiceIdentifier,
        SkillIdentifier,
        TimeOrderFrom,
        TimeOrderTo,
        sessionIdentifier,
      );
      if (response.data) {
        dispatch(autoAssignPending(false));
        dispatch({
          type: GET_AVAILABLE_RESOURCE_AUTO_ASSIGN,
          availableResourcesAutoAssign: response.data,
        });
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
      dispatch(autoAssignPending(false));
      dispatch({
        type: GET_AVAILABLE_RESOURCE_AUTO_ASSIGN,
        availableResourcesAutoAssign: {},
      });
    }
  };
}

export function clearAutoAssignResources() {
  return async dispatch => {
    dispatch({
      type: GET_AVAILABLE_RESOURCE_AUTO_ASSIGN,
      availableResourcesAutoAssign: [],
    });
  };
}

export function autoAssignCreateRequest(
  ServiceIdentifier,
  contactPerson,
  contactPersonUponInterpretation,
  language,
  dateRange,
  customer,
  sessionIdentifier,
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { token } = state.authentication;
      dispatch(showLoadingOverlay());
      const response = await api.autoAssignCreateOrder(
        token,
        ServiceIdentifier,
        contactPerson,
        contactPersonUponInterpretation,
        language,
        dateRange,
        customer,
        sessionIdentifier,
      );
      if (response.data) {
        dispatch({
          type: CREATE_NEW_AUTO_ASSIGN_ORDER,
          returnedOrder: response.data,
        });
        broadCast('SuccessFullOrderCreation');
      }
    } catch (error) {
      dispatch({
        type: CREATE_NEW_AUTO_ASSIGN_ORDER_ERROR,
        assignOrderError: error,
      });

      transvoiceLog(error, logApiLevel.error);
      dispatch(hiddenLoadingOverlay());
      throw error;
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function autoAssignOpeningHours({ DateFrom, DateTo }) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { token } = state.authentication;
      dispatch(showLoadingOverlay());
      const response = await api.getAutoAssignOpeningHours({
        token,
        DateFrom,
        DateTo,
      });
      if (response.data) {
        dispatch({
          type: GET_AUTO_ASSIGN_OPENING_HOURS,
          bookDirectOpeningHours: response.data,
        });
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
      dispatch(hiddenLoadingOverlay());
      throw error;
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function setOrderRating(
  orderIdentifier,
  rate,
  comment,
  reason,
  callback = () => {},
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { token } = state.authentication;
      dispatch({
        type: UPDATE_ASSIGNMENTS_RATINGS,
        orderIdentifier,
        rate,
        comment,
        reason: reason != '' ? reason : null,
      });
      dispatch(showLoadingOverlay());
      const response = await api.setCustomerRating(
        token,
        orderIdentifier,
        rate,
        comment,
        reason != '' ? reason : null,
      );
      if (!response.data || response.data.Errors) {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};

        throw new Error(
          Translate({
            content: 'error.orderRateError',
            params: {
              content: JSON.stringify(errorResponse),
            },
          }),
        );
      }
      if (response.data && !response.data.Errors) {
        if (callback && typeof callback === 'function') callback(response.data);
        /*  showToastMessage(
          'success',
          Translate({ content: 'singleAssignment.ratedSuccessfull' }),
        ); */
      }
    } catch (error) {
      broadCast('orderRatefailure', orderIdentifier);
      dispatch({
        type: UPDATE_ASSIGNMENTS_RATINGS,
        orderIdentifier,
        rate: null,
        comment: null,
      });
      transvoiceLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function UpdateContactPersons(contactPerson) {
  return dispatch => {
    dispatch({
      type: UPDATE_CONTACT_PERSONS,
      contactPersons: [contactPerson],
    });
  };
}

export function GetRatingReasons() {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    try {
      const response = await api.fetchRatingReasons(token);
      if (response.data && !response.data.Errors) {
        let ratingReasons = [];
        if (response.data.ComplaintReasons) {
          ratingReasons = response.data.ComplaintReasons.map((item, i) => ({
            ...item,
            value: item.ComplaintReasonDeliveryIdentifier,
            name: item.ComplaintReasonDeliveryName,
          }));
        }

        dispatch({
          type: UPDATE_RATING_REASONS,
          ratingReasons,
        });
      } else {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};
        throw new Error(
          `GetRatingReasons error ${JSON.stringify(errorResponse)}`,
        );
      }
    } catch (error) {
      transvoiceLog(error);
    } finally {
    }
  };
}
