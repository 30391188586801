import React, { Component } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import PropTypes from 'prop-types';
import {
  Modal,
  Translate,
  TransvoiceButton,
  TextField,
} from '../../../Share/components';
import './style.scss';

const propTypes = {
  onCloseClicked: PropTypes.func,
  isVisible: PropTypes.bool,
};

const defaultProps = {
  onCloseClicked: () => {},
  isVisible: false,
};

class ImprovementSuggestionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionValue: '',
    };
  }

  onClose = () => {
    const { onCloseClicked } = this.props;
    onCloseClicked();
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
  };

  render() {
    const { validatorListener, isVisible, onCloseClicked } = this.props;
    const { descriptionValue } = this.state;
    return (
      <Modal
        className="tv-reportquality__improvementsuggest-modal"
        onCloseClicked={onCloseClicked}
        isVisible={isVisible}
      >
        <div className="tv-modal__content">
          <ValidatorForm
            className="tv-reportquality-form"
            onSubmit={this.handleSubmit}
            ref="form"
          >
            <div className="form-group col-lg-12 col-12 mr-lg-2">
              <div className="tv-reportquality-form__label">
                <label>
                  <Translate content="reportQuality.descriptionFieldLabel" />
                </label>
              </div>
              <div className="tv-reportquality-form__input">
                <TextField
                  validatorListener={validatorListener}
                  placeholder={Translate({
                    content: 'reportQuality.descriptionFieldPlaceholder',
                  })}
                  onChange={this.handleChange}
                  cols="10"
                  rows="3"
                  maxLength="1000"
                  multiline
                  className="ellipsis pl-3 py-1 tv-single-assign-form__control label-height"
                  name="descriptionValue"
                  value={descriptionValue}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="tv-workassignment__detail-buttons tv-reportquality-form__buttons justify-content-between tv-display-flex">
                <TransvoiceButton
                  type="secondary"
                  buttonType="submit"
                  text={Translate({
                    content: 'reportQuality.submitButton',
                  })}
                  onClick={() => {
                    this.onClose();
                  }}
                />
                <TransvoiceButton
                  type="line-primary"
                  text={Translate({
                    content: 'reportQuality.cancelButton',
                  })}
                  onClick={() => {
                    this.onClose();
                  }}
                />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </Modal>
    );
  }
}

ImprovementSuggestionsModal.propTypes = propTypes;
ImprovementSuggestionsModal.defaultProps = defaultProps;

export default ImprovementSuggestionsModal;
