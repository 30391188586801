import React, { useRef } from 'react';
import { Translate, Modal, TransvoiceButton } from '../../../Share/components';
import './style.scss';
import ReactToPrint from 'react-to-print';

function OnDemand({ onCloseClicked, isVisible }) {
  const componentRef = useRef(null);
  return (
    <Modal
      className="tv-ondemand__modal"
      isVisible={isVisible}
      headerRender={<></>}
      ieOptionClassName={'ondemand-holder'}
      onCloseClicked={onCloseClicked}
      ref={componentRef}
    >
      <div className="tv-modal__holder__custom-header">
        <h2>On-Demand</h2>
      </div>
      <div className="tv-ondemand__container">
        <div className="tv-ondemand__top-container">
          <img src="/images/ondemand-logo.png" alt="ondemand-book" />
          <div>
            <div className="tv-ondemand__top-container__title">
              Hur fungerar On-Demand?
            </div>
            <div className="tv-ondemand__top-container__message">
              Med On-Demand erbjuder vi direkttolkning på de tio vanligaste
              språken via telefon eller video. Genom att ringa direktnumret
              eller klicka på videolänken till respektive språk så kommer du få
              hjälp av en av våra tolkar.
            </div>
          </div>
        </div>
        <div className="tv-ondemand__list">
          <SingleList language="Arabiska" teleNo="011-473 00 94" />
          <SingleList language="Albanska" teleNo="011-473 00 93" />
          <SingleList language="Dari" teleNo="011-473 00 73" />
          <SingleList language="Polska" teleNo="011-473 00 74" />
          <SingleList language="Persiska" teleNo="011-473 00 79" />
          <SingleList language="Somaliska" teleNo="011-473 00 71" />
          <SingleList language="Tigrinja " teleNo="011-473 00 80" />
          <SingleList language="Turkiska" teleNo="011-473 00 77" />
          <SingleList language="Ukrainska" teleNo="011-473 00 75" />
          <SingleList language="Vietnamesiska" teleNo="011-473 00 87" />
        </div>
        <div className="tv-ondemand__actions">
          <div className="tv-ondemand__actions-print">
            <ReactToPrint
              trigger={() => (
                <div className="tv-ondemand__actions-print__icon">
                  {Translate({
                    content: 'reportQuality.printButton',
                  })}
                </div>
              )}
              content={() => componentRef.current}
            />
            <img src="/images/print-icon.png" />
          </div>
          <TransvoiceButton
            buttonType="submit"
            type="secondary"
            text="Klar"
            onClick={onCloseClicked}
          />
        </div>
      </div>
    </Modal>
  );
}

const SingleList = ({ language, teleNo }) => {
  return (
    <div className="tv-ondemand__list-item">
      <div className="tv-ondemand__list-language">{language}</div>
      <div className="tv-ondemand__list-tel">
        <div>Telefon: &nbsp;</div>
        <div className="tv-ondemand__list-tel__number">
          <a href={'tel:' + teleNo}>{teleNo}</a>
        </div>
      </div>
      <div className="tv-ondemand__list-tel">
        <div>Video: &nbsp;</div>
        <div className="tv-ondemand__list-tel__number">
          <a
            href="https://app.telavox.com/video?room=2b8f04ec-a434-4c4f-8f0e-892914b88946"
            target="_blank"
            rel="noopener noreferrer"
            className="video-link"
          >
            Klicka här
          </a>
        </div>
      </div>
    </div>
  );
};

export default OnDemand;
