import React, { useState } from 'react';
import { Modal, TransvoiceButton, Translate } from '../../../Share/components';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { getDateFormated } from '../../../Share/utils';
import { convertStringToDate } from '../../../Share/utils/dateUtil';

function CopyBookingConfirm({
  isVisible,
  hideCopyBooking,
  assignment,
  setSelectedSlot,
  displayAcoliSuccessModal,
}) {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const {
    dateRange,
    typeOfAssignment,
    contactPerson,
    orderContactPerson,
    contactPersons,
    language,
  } = assignment;

  const filterContactPerson = contactPersons.filter(
    obj => obj.value === contactPerson,
  )[0];
  const previewContactPerson = filterContactPerson
    ? filterContactPerson.name
    : Translate({ content: 'general.none' });

  const filterContactOrderPerson = contactPersons.filter(
    obj => obj.value === orderContactPerson,
  )[0];
  const previewContactOrderPerson = filterContactOrderPerson
    ? filterContactOrderPerson.name
    : Translate({ content: 'general.none' });

  const previewOrderTime = sess => {
    let session = cloneDeep(sess);

    const beginningTime = moment({
      h: session.startTime.hours(),
      m: session.startTime.minutes(),
    });
    const endTime = moment({
      h: session.endTime.hours(),
      m: session.endTime.minutes(),
    });
    session.ranges.startDate = session.ranges.startDate.clone();
    session.ranges.endDate = session.ranges.endDate.clone();
    if (
      (beginningTime.isAfter(endTime) || endTime.isSame(beginningTime)) &&
      session.ranges.endDate.isSame(session.ranges.startDate, 'day')
    ) {
      session.ranges.endDate.add(1, 'day');
    }
    const startDate = getDateFormated(
      convertStringToDate(session.ranges.startDate),
      'DD MMMM',
    );
    const endDate = getDateFormated(
      convertStringToDate(session.ranges.endDate),
      'DD MMMM',
    );
    const startTimeStr = moment(session.startTime).format('HH:mm');
    const endTimeStr = moment(session.endTime).format('HH:mm');
    let orderDateTimeString = `${startTimeStr} - ${endTimeStr}, ${startDate}`;

    if (startDate !== endDate) {
      orderDateTimeString = `${startTimeStr}, ${startDate} - ${endTimeStr}, ${endDate}`;
    }
    return orderDateTimeString;
  };

  return (
    <Modal
      className="tv-alternativeTid__modal"
      isVisible={isVisible}
      headerRender={<></>}
      ieOptionClassName={'alternativeTid-holder'}
      onCloseClicked={() => {
        hideCopyBooking(false);
      }}
    >
      <div className="tv-modal__holder__custom-header">
        <h2>
          Det finns redan bokning den tid och språk du valt, du kan samordna
          eller behålla din tid.
        </h2>
      </div>
      <div className="tv-alternativeTid__container">
        <div className="tv-alternativeTid__top-container">
          <div className="tv-alternativeTid__top-container__title">
            Bokningsinformation
          </div>
          <div className="tv-alternativeTid__top-container__list">
            <BookingInfoItem label="Språk" value={language.name} />
            <BookingInfoItem label="Bokning" value={typeOfAssignment.name} />
            <BookingInfoItem label="Tid" value={previewOrderTime(dateRange)} />
            <BookingInfoItem label="Tolk" value={previewContactPerson} />
            <BookingInfoItem label="" value="" />
            <BookingInfoItem
              label="Beställare"
              value={previewContactOrderPerson}
            />
          </div>
        </div>

        <div className="tv-alternativeTid__time-container">
          <div className="tv-alternativeTid__time-container__title">
            Vänligen välj alternativ tid
          </div>
          <div className="tv-alternativeTid__time-container__title-date">
            Onsdag 21 augusti 2024
          </div>
          <div className="tv-alternativeTid__time-container__box tv-copy-booking-box">
            <div className="tv-alternativeTid__time-container__box-section">
              <div className="time-container__date">
                Anslutande tid innan bokad tid
              </div>
              <div
                className={`time-slot ${
                  selectedTimeSlot === 1 ? 'selected' : null
                }`}
                onClick={() => {
                  setSelectedTimeSlot(1);
                  setSelectedSlot({
                    startDate: '2024-08-21 12:00:00',
                    endDate: '2024-08-21 13:00:00',
                  });
                }}
              >
                <div className="time-limit">12.00-13.00</div>
              </div>
            </div>
            <div className="tv-alternativeTid__time-container__box-section">
              <div className="time-container__date">Behåll min bokade tid</div>
              <div
                className={`time-slot ${
                  selectedTimeSlot === 2 ? 'selected' : null
                }`}
                onClick={() => {
                  setSelectedTimeSlot(2);
                  setSelectedSlot({
                    startDate: '2024-08-21 13:00:00',
                    endDate: '2024-08-21 14:00:00',
                  });
                }}
              >
                <div className="time-limit">13.00-14.00</div>
              </div>
            </div>
            <div className="tv-alternativeTid__time-container__box-section">
              <div className="time-container__date">
                Anslutande tid efter bokad tid
              </div>
              <div
                className={`time-slot ${
                  selectedTimeSlot === 3 ? 'selected' : null
                }`}
                onClick={() => {
                  setSelectedTimeSlot(3);
                  setSelectedSlot({
                    startDate: '2024-08-21 14:00:00',
                    endDate: '2024-08-21 15:00:00',
                  });
                }}
              >
                <div className="time-limit">14.00-15.00</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tv-alternativeTid__actions"
          style={{ marginTop: '150px' }}
        >
          <TransvoiceButton
            className="cancel-button"
            text="Tillbaka"
            onClick={() => {
              hideCopyBooking(false);
            }}
          />
          <TransvoiceButton
            text="Bekräfta ny tid"
            className={`submit-button ${
              selectedTimeSlot !== null ? 'continue' : null
            }`}
            onClick={() => {
              if (selectedTimeSlot !== null) {
                displayAcoliSuccessModal(true);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

const BookingInfoItem = ({ label, value }) => {
  return (
    <div className="tv-alternativeTid__top-container__list-item">
      {label && <div>{label}: &nbsp;</div>}
      {!label && <div></div>}
      <div>{value}</div>
    </div>
  );
};

export default CopyBookingConfirm;
