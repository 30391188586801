import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/sv';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@progress/kendo-theme-material/dist/all.css';
import '../Share/styles/index.scss';
import {
  Dashboard,
  MyAssignment,
  ContactTransvoice,
  Logout,
  Setting,
  ManageUsers,
  Login,
  ReportQuality,
  SetNewPassword,
  TimeSheet,
  KPIDashboard,
  ReleasesPage,
  NotificationPage,
  ResetPassword,
  Frasbank,
  MessageService,
  MainDashboard,
  WorldishLogin,
} from './components';

import { MainLayout, LoginLayout } from './layouts';
import { PrivateRoute, PublicRoute, ReportRoute } from './router';
import history from '../Share/utils/history';
import {
  NotFound,
  MobileApp,
  Version,
  TermAndCondition,
  PrivacyPolicy,
  Translate,
} from '../Share/components/';
import { LOCAL_STORE_LANGUAGE } from '../Share/constants/localStorage';

let lang = localStorage.getItem(LOCAL_STORE_LANGUAGE);
switch (lang) {
  case 'en':
    lang = 'en';
    break;
  case 'se':
    lang = 'sv-SE';
    break;
  default:
    lang = 'sv-SE';
    break;
}

localStorage.setItem(
  'clarityKey',
  process.env.REACT_APP_CLARITY_INTEGRATION_PROJECT_KEY,
);

moment.lang(lang, {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});
const INITIAL_STATES = {
  showKPIDashboard: false,
};
class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATES;
  }
  componentDidMount() {
    document.title = Translate({ content: 'login.clientPortal' });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            layout={MainLayout}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            layout={MainLayout}
            component={MainDashboard}
          />
          <PrivateRoute
            exact
            path="/reports-dashboard"
            layout={MainLayout}
            component={KPIDashboard}
          />
          <ReportRoute exact path="/reports/:id" layout={MainLayout} />

          <PrivateRoute
            exact
            path="/my-assignment"
            layout={MainLayout}
            component={MyAssignment}
          />
          <PrivateRoute
            exact
            path="/contact-transvoice"
            layout={MainLayout}
            component={ContactTransvoice}
          />
          <PrivateRoute
            exact
            path="/frasbank"
            layout={MainLayout}
            component={Frasbank}
          />
          <PrivateRoute
            exact
            path="/message-service"
            layout={MainLayout}
            component={MessageService}
          />

          <PrivateRoute
            exact
            path="/settings"
            layout={MainLayout}
            component={Setting}
          />
          <PrivateRoute
            exact
            path="/settings/manageusers"
            layout={MainLayout}
            component={ManageUsers}
          />

          <PrivateRoute
            exact
            path="/report-quality"
            layout={MainLayout}
            component={ReportQuality}
          />
          <PrivateRoute
            exact
            path="/time-sheet"
            layout={MainLayout}
            component={TimeSheet}
          />

          <PrivateRoute
            exact
            path="/releases-page"
            layout={MainLayout}
            component={ReleasesPage}
          />

          <PrivateRoute
            exact
            path="/notifications"
            layout={MainLayout}
            component={NotificationPage}
          />

          <Route exact path="/mobile-app/login" component={MobileApp} />
          <PublicRoute
            exact
            path="/logout"
            layout={MainLayout}
            component={Logout}
          />
          <PublicRoute
            exact
            path="/login"
            layout={LoginLayout}
            component={Login}
          />
          <PublicRoute
            exact
            path="/set-new-password"
            layout={LoginLayout}
            component={SetNewPassword}
          />
          <PublicRoute
            exact
            path="/WorldishLogin"
            layout={MainLayout}
            component={WorldishLogin}
          />
          {/* <PublicRoute
            exact
            path="/reset-password"
            layout={LoginLayout}
            component={ResetPassword}
          /> */}

          <PublicRoute
            exact
            path="/terms-and-conditions"
            layout={LoginLayout}
            component={TermAndCondition}
          />
          <PublicRoute
            exact
            path="/privacy-policy"
            layout={LoginLayout}
            component={PrivacyPolicy}
          />
          <Route exact path="/version" component={Version} />
          {/* Note: we must to put NotFound at the end of the Switch statement */}
          <PrivateRoute layout={MainLayout} component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default Root;
