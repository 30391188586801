import React, { Component } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, NavLink } from 'react-router-dom';
import { isEmpty } from 'lodash/core';
import SingleAssignmentFormPreview from './SingleAssignmentFormPreview';
import {
  Translate,
  TransvoiceButton,
  RouteLeavingGuard,
  Modal,
  TextField,
} from '../../../Share/components';
import { createNewOrder } from '../../actions';
import history from '../../../Share/utils/history';
import { isIE } from '../../../Share/utils';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import { subscribe } from '../../actions/eventBus';
import SuccessMessage from './SuccessMessage';
import { useState } from 'react';
import { updateOrder } from '../../actions/workAssignment';
import moment from 'moment';

const propTypes = {
  assignment: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  onCreateNewOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  isBasic: PropTypes.bool,
  onClose: PropTypes.func,
  displayAcoliModal: PropTypes.func,
  displayCopyBooking: PropTypes.func,
};

const defaultProps = {
  assignment: {},
  onClose: () => {},
  isBasic: false,
  onCreateNewOrder: () => {},
  onUpdateOrder: () => {},
  displayAcoliModal: () => {},
  displayCopyBooking: () => {},
};

const bookedDate = moment('2024-08-21');
const bookedStartTime = '13:00:00';
const bookedEndTime = '14:00:00';
const bookedService = 'Kontakttolkning';

class PreviewAssignment extends Component {
  state = {
    isDirty: true,
    orderEmail: '',
  };

  scrollRef = React.createRef();
  componentRef = React.createRef();
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.orderEmail === '') {
      return {
        ...prevState,
        orderEmail: nextProps.assignment.orderEmail,
      };
    }
  }

  handleCopyBooking = () => {
    const { assignment } = this.props;
    const { dateRange, typeOfAssignment } = assignment;
    const rangeStartDate = moment(dateRange.ranges.startDate);

    if (bookedDate.isSame(rangeStartDate, 'day')) {
      const bookedStart = moment(bookedStartTime, 'HH:mm:ss');
      const bookedEnd = moment(bookedEndTime, 'HH:mm:ss');

      const rangeStart = moment(dateRange.startTime).format('HH:mm:ss');
      const rangeEnd = moment(dateRange.endTime).format('HH:mm:ss');

      if (
        bookedStart.format('HH:mm:ss') === rangeStart &&
        bookedEnd.format('HH:mm:ss') === rangeEnd
      ) {
        if (typeOfAssignment.name === bookedService) {
          return true;
        }
      }
    }
  };

  handleSubmit = () => {
    const { assignment, displayAcoliModal, displayCopyBooking } = this.props;
    const { orderEmail } = this.state;
    assignment.isBasic = this.props.isBasic;
    if (assignment.isEdit) {
      this.props.onUpdateOrder({
        ...assignment,
        orderEmail,
      });
    } else {
      const { language } = assignment;
      if (language.name === 'Hausa') {
        displayAcoliModal(true);
      } else if (this.handleCopyBooking()) {
        displayCopyBooking(true);
      } else {
        this.props.onCreateNewOrder({
          ...assignment,
          orderEmail,
        });
      }
    }
  };
  render() {
    const { assignment, onClose, isBasic } = this.props;
    const { isDirty, orderEmail } = this.state;

    return (
      <React.Fragment>
        <div
          className={`d-flex flex-column tv-workassignment__modal-${
            isBasic ? 'scroll-container' : 'scroll-container'
          } tv-workassignment__modal-detail-contailer border-top-0`}
        >
          {/* <Scrollbars ref={this.scrollRef}> */}

          <SingleAssignmentFormPreview
            ref={this.componentRef}
            {...assignment}
            showBasicPreview={isBasic}
            showEditButton
            isDirty={isDirty}
            changeIsDirty={(status, callback) => {
              this.setState({ isDirty: status }, callback);
            }}
          />
          {/* </Scrollbars> */}
        </div>

        <div className="d-flex flex-column tv-workassignment__modal-footer">
          <div className="tv-workassignment__detail-links ml-2 mt-2">
            {/* <div className="tv-single-assign-form__preview-property mb-0 text-left">
              <div className="property-label">
                <Translate content="singleAssignment.orderContacEmail" />:
              </div>
              <ValidatorForm ref="form" onSubmit={() => {}}>
                <EditableField
                  value={orderEmail}
                  onChange={value => {
                    if (value != '') {
                      this.setState({
                        orderEmail: value,
                      });
                    }
                  }}
                  editableButtonclassName="tv-workassignment__detail-links-item tv-workassignment__detail-links-item-button my-1 p-0"
                  readOnlyClassName="property-value"
                />
              </ValidatorForm>
            </div>*/}
            <ReactToPrint
              trigger={() => (
                <div className="tv-workassignment__detail-links-item justify-content-end mb-1">
                  {Translate({
                    content: 'reportQuality.printButton',
                  })}
                </div>
              )}
              content={() => this.componentRef.current}
            />
          </div>
          <div className="tv-workassignment__detail-buttons tv-display-flex">
            <TransvoiceButton
              type="secondary"
              className="tv-single-assign-form__button-preview"
              buttonType="button"
              text={Translate({
                content: 'previewAssignment.confirmNow',
              })}
              onClick={this.handleSubmit}
            />
            <TransvoiceButton
              type="line-primary"
              buttonType="button"
              className="w-50 ml-1"
              text={Translate({
                content: 'singleAssignment.goBack',
              })}
              onClick={e => {
                onClose();
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PreviewAssignment.propTypes = propTypes;
PreviewAssignment.defaultProps = defaultProps;

const mapStateToProps = state => {
  const { assignmentTemp } = state.workAssignment;
  const assignment = {
    ...assignmentTemp,
  };
  return {
    assignment,
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateNewOrder: assignment => {
    dispatch(createNewOrder(assignment));
  },
  onUpdateOrder: assignment => {
    dispatch(updateOrder(assignment));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAssignment);

const EditableField = ({
  value,
  onChange,
  readOnlyClassName,
  editableButtonClass,
}) => {
  const [editMode, seteditMode] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);
  const [isFieldValid, setIsFieldValid] = useState(true);
  const handleValueChange = e => {
    setFieldValue(e.target.value);
  };
  return editMode ? (
    <div className="d-flex w-100">
      <div className="d-flex w-100 flex-column">
        <TextField
          type="email"
          onChange={handleValueChange}
          validatorListener={e => {
            if (e !== isFieldValid) {
              setIsFieldValid(e);
            }
          }}
          onBlur={() => {
            if (isFieldValid) {
              onChange(fieldValue);
              seteditMode(false);
            }
          }}
          className={readOnlyClassName}
          name="contact-email"
          value={fieldValue}
          validators={['isEmail']}
          errorMessages={[
            Translate({ content: 'validator.incorrectEmailFormat' }),
          ]}
        />
      </div>
      <TransvoiceButton
        type="text"
        disable={!isFieldValid}
        className={editableButtonClass}
        buttonType="submit"
        text={Translate({
          content: 'general.save',
        })}
        onClick={() => {
          if (isFieldValid) {
            onChange(fieldValue);
            seteditMode(false);
          }
        }}
      />
    </div>
  ) : (
    <div className="d-flex">
      <div className={`${readOnlyClassName} edit-field`} title={value}>
        {value != '' && value ? value : '-'}
      </div>
      <TransvoiceButton
        type="text"
        className={editableButtonClass}
        buttonType="button"
        text={Translate({
          content: 'general.edit',
        })}
        onClick={() => seteditMode(true)}
      />
    </div>
  );
};
