import React, { useState } from 'react';
import './styles.scss';

const Chatbot = () => {
  const [showChatbot, setShowChatbot] = useState(false);

  return (
    <div className="chatbot-container">
      {showChatbot && (
        <div className="chatbot-wrapper">
          <div className="chatbot-header">
            <div className="chatbot-header-title">Transvoice chatt</div>
            <div className="chatbot-header-close">
              <button>
                <img
                  src="/images/close.svg"
                  onClick={() => setShowChatbot(false)}
                />
              </button>
            </div>
          </div>

          <div className="chatbot-body">
            <div className="chat-display-section">
              <div className="receiver">
                <img
                  src="/images/logo-mobile.png"
                  className="receiver_avatar"
                />
                <div className="receiver_msg">
                  Välkommen till Transvoice chatbot! <br/> Ställ en fråga eller välj en
                  av nedan alternativ så hjälper jag dig vidare.
                </div>
              </div>
              <div className="receiver _multiple">
                <div className="receiver_msg">
                  <div className="receiver-header">Kom igång</div>
                  <div className="initilal-questions">
                    Hur gör jag en förbokning?
                  </div>
                  <div className="initilal-questions">
                    Hur bokar jag TolkDirekt?
                  </div>
                  <div className="initilal-questions">
                    Hur ändrar, avbokar eller kopierar en bokning?
                  </div>
                </div>
              </div>

              {/* <div className="sender">Hur bokar jag TolkDirekt?</div> */}
            </div>
            <div className="chat-send-section">
              <input
                type="text"
                className="msg_input"
                placeholder="Skriv ditt meddelande här... "
              />
              <button type="button" className="send_msg_btn">
                <img src="/images/chevron.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      )}

      <button
        className="chatbot-button"
        onClick={() => setShowChatbot(!showChatbot)}
      >
        <img src="/images/chat-icon.svg" alt="" />
        Chatt
      </button>
    </div>
  );
};

export default Chatbot;
