import React from 'react';
import {
  Translate,
  Modal,
  TransvoiceButton,
  TransvoiceDateRangePicker,
  TextField,
  DropdownList,
} from '../../../Share/components';
import { ValidatorForm } from 'react-form-validator-core';
import { clone } from 'lodash/core';
import moment from 'moment';

function MessageServiceForm({
  skills,
  item,
  handleChange,
  isVisible,
  onCloseClicked,
  handleSubmit,
}) {
  const handleSessionDateChange = (e, element) => {
    const { date } = item;
    const { key } = date.ranges;
    element.onToggleMenu();
    if (key && e[key]) {
      const { startDate, endDate } = e[key];
      if (startDate && endDate) {
        handleSessionChange(
          {
            startDate: moment(startDate),
            endDate: moment(endDate),
            key,
          },
          'ranges',
        );
      }
    }
  };

  const handleSessionChange = (e, key) => {
    const dateRange = clone(item.date); // use clone() to fix object reference issue
    dateRange[key] = e;
    handleChange({ date: dateRange });
  };

  const handleOnChange = event => {
    const {
      target: { name, value },
    } = event;
    handleChange({ [name]: value });
  };

  const getValuefromList = (filterText, list) => {
    if (filterText === null || filterText === '') return null;
    const filteredItems = list.filter(x => x.value === filterText);

    return filteredItems ? filteredItems[0] : null;
  };

  const handleRadioButtonChange = e => {
    let value = parseInt(e.target.value);
    handleChange({ feedbackValue: value });
  };

  const {
    date,
    bookingNumber,
    clientName,
    feedback,
    telephoneNo,
    language,
    message,
  } = item;

  return (
    <Modal
      className="tv-message-service__modal"
      isVisible={isVisible}
      headerRender={<></>}
      ieOptionClassName={'message-service-holder'}
      onCloseClicked={onCloseClicked}
    >
      <div className="tv-modal__holder__custom-header">
        <h2>Beställ meddelandeservice</h2>
      </div>
      <div className="tv-message-service__container">
        <div className="left-conatiner">
          <div>
            <p>
              Meddelandeservice är en direkt och okomplicerad
              kommunikationskanal där man skickar korta, klara meddelanden till
              klienten på deras modersmål.
            </p>
            <p>
              Man kan också välja att vi ska ringa klienten, då gör vi upp till
              tre spridda försök under dagen.
            </p>
            <p>
              Ett exempel på ett sådant meddelande kan vara:{' '}
              <i>
                "Här kommer en påminnelse om att du inte ska äta 12h innan
                operationstiden. Samt inte dricka minst 2h innan.”
              </i>
            </p>
          </div>
          <div>
            <h2>Exempelbild:</h2>
            <img
              className="message-service_alert"
              src="/images/message-service_alert.png"
            />
          </div>
        </div>
        <div className="right-conatiner">
          <ValidatorForm onSubmit={handleSubmit}>
            <div className="container-1">
              <div className="form-group col-md-6">
                <label>
                  <Translate content="messageService.date" />
                </label>
                <div className="tv-single-assign-form__input">
                  <TransvoiceDateRangePicker
                    displayFormat="YYYY-MM-DD"
                    ranges={date.ranges}
                    onChange={handleSessionDateChange}
                  />
                </div>
              </div>
              <div className="col-6 col-md-6 form-group">
                <label>
                  <Translate content="messageService.bookingNumber" />
                </label>
                <TextField
                  className="tv-single-assign-form__control"
                  placeholder={Translate({
                    content: 'messageService.bookingNoPlaceholder',
                  })}
                  name="bookingNumber"
                  onChange={handleOnChange}
                  value={bookingNumber}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 form-group">
              <label>
                <Translate content="messageService.clientName" />
              </label>
              <TextField
                className="tv-single-assign-form__control"
                placeholder={Translate({
                  content: 'messageService.clientNamePlaceholder',
                })}
                name="clientName"
                onChange={handleOnChange}
                value={clientName}
              />
            </div>
            <div className="col-12 col-md-12 form-group">
              <label>
                <Translate content="messageService.feedback" />
              </label>
              <div className="d-flex">
                <TransvoiceRadioButton
                  text={Translate({
                    content: 'messageService.sms',
                  })}
                  name="feedback"
                  checked={!feedback}
                  value={1}
                  onChange={handleRadioButtonChange}
                  className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                />
                <TransvoiceRadioButton
                  text={Translate({
                    content: 'messageService.phoneCall',
                  })}
                  name="feedback"
                  checked={feedback}
                  value={2}
                  onChange={handleRadioButtonChange}
                  className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container pl-4"
                />
              </div>
            </div>
            <div className="col-12 col-md-12 form-group">
              <label>
                <Translate content="messageService.telephoneNo" />
              </label>
              <TextField
                className="tv-single-assign-form__control"
                placeholder={Translate({
                  content: 'messageService.telephoneNoPlaceholder',
                })}
                name="telephoneNo"
                onChange={handleOnChange}
                value={telephoneNo}
              />
            </div>
            <div className="col-12 col-md-12 form-group">
              <label>
                <Translate content="messageService.language" />
              </label>
              <DropdownList
                onChange={handleOnChange}
                name="language"
                required
                value={getValuefromList(language.value, skills)}
                className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                options={skills}
              />
            </div>
            <div className="col-12 col-md-12 form-group">
              <label>
                <Translate content="messageService.message" />
              </label>
              <TextField
                className="tv-single-assign-form__control"
                placeholder={Translate({
                  content: 'messageService.messagePlaceholder',
                })}
                multiline
                name="message"
                onChange={handleOnChange}
                value={message}
                maxLength={300}
              />
            </div>
            <div className="col-12 col-md-12 tv-display-flex">
              <TransvoiceButton
                type="line-primary"
                buttonType="button"
                className="w-50 mr-3"
                text={Translate({
                  content: 'general.close',
                })}
                onClick={onCloseClicked}
              />
              <TransvoiceButton
                type="secondary"
                buttonType="submit"
                className="w-50 ml-3"
                text={Translate({
                  content: 'general.continue',
                })}
              />
            </div>
          </ValidatorForm>
        </div>
      </div>
    </Modal>
  );
}

export default MessageServiceForm;

const TransvoiceRadioButton = ({
  className,
  name,
  onChange,
  checked,
  value,
  text = '',
  disabled = false,
}) => (
  <label className={`tv-form__checkbox-container ${className}`}>
    <input
      disabled={disabled}
      type="radio"
      name={name}
      onChange={onChange}
      defaultChecked={checked}
      value={value}
    />
    <span className="tv-form__checkbox-checkmark" />
    {text}
  </label>
);
