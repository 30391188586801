import * as actionTypes from '../actions/ActionTypes';

const INITIAL_STATE = {
  showLoadingOverlay: false,
  selectedAgreement: JSON.parse(localStorage.getItem('SELECTED_AGREEMENT')) || {
    id: 0,
  },
  dashboardFilter: {},
  showOnDemand: false,
  showMessageService: false,
  showQuickOrder: false,
  showDirectBooking: false,
  displayUngradedAssignments: false,
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;

    case actionTypes.TOGGLE_LOADING_OVERLAY: {
      const { showLoadingOverlay } = action;
      return {
        ...state,
        showLoadingOverlay,
      };
    }
    case actionTypes.SET_USER_AGREEMENT: {
      const { selectedAgreement } = action;
      localStorage.setItem(
        'SELECTED_AGREEMENT',
        JSON.stringify(selectedAgreement),
      );
      return {
        ...state,
        selectedAgreement,
      };
    }
    case actionTypes.SET_CROSS_FILTER: {
      const { filter, sourceId } = action;
      let newDashboardFilter = { ...state.dashboardFilter };
      if (newDashboardFilter.key === undefined) {
        newDashboardFilter = { ...filter, sourceIds: [sourceId] };
      } else {
        const IsSourceIdNew =
          state.dashboardFilter.sourceIds.indexOf(sourceId) === -1;
        if (
          newDashboardFilter.key === filter.key &&
          newDashboardFilter.value === filter.value &&
          IsSourceIdNew
        ) {
          newDashboardFilter.sourceIds = [
            ...newDashboardFilter.sourceIds,
            sourceId,
          ];
        }
        if (
          newDashboardFilter.key !== filter.key ||
          newDashboardFilter.value !== filter.value
        ) {
          newDashboardFilter = { ...filter, sourceIds: [sourceId] };
        }
      }
      return {
        ...state,
        dashboardFilter: newDashboardFilter,
      };
    }
    case actionTypes.REMOVE_CROSS_FILTER: {
      return {
        ...state,
        dashboardFilter: {},
      };
    }
    case actionTypes.DISPLAY_ONDEMAND_MODAL:
      const { display } = action;
      return {
        ...state,
        showOnDemand: display,
      };
    case actionTypes.DISPLAY_MESSAGE_SERVICE_MODAL:
      const { show } = action;
      return {
        ...state,
        showMessageService: show,
      };
    case actionTypes.DISPLAY_QUICK_ORDER:
      const { showQuickOrder } = action;
      return {
        ...state,
        showQuickOrder,
      };
    case actionTypes.DISPLAY_DIRECT_BOOKING:
      const { showDirectBooking } = action;
      return {
        ...state,
        showDirectBooking,
      };
    case actionTypes.DISPLAY_UNGRADED_ASSIGNMENTS:
      const { displayUngradedAssignments } = action;
      return {
        ...state,
        displayUngradedAssignments,
      };
    default:
      return state;
  }
};

export default app;
