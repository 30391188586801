/* eslint-disable max-len */
import axios from 'axios';
import { LOCAL_STORE_APIKEY } from '../../Share/constants';
import { errorInterceptor, responseInterceptor } from './apiInterceptor';

const apiVersion = process.env.REACT_APP_REPORT_API_VERSION;
const apiKey = process.env.REACT_APP_REPORT_API_KEY;
const reportAxios = axios.create({
  baseURL: process.env.REACT_APP_REPORT_API,
});
// api interceptors
reportAxios.interceptors.response.use(responseInterceptor, errorInterceptor);

// eslint-disable-next-line import/prefer-default-export
export function GetDataFromScource(
  urlPart,
  from,
  to,
  agreements,
  customerNumber,
  groupColumns = '',
) {
  return reportAxios.get(
    `/api/${apiVersion}/reportData/${urlPart}?from=${from}&to=${to}&agreements=${agreements}&groupColumns=${
      groupColumns || ''
    }`,
    {
      headers: {
        customerNumber:
          localStorage.getItem('SELECTER_CUSTOMER_NUMBER') || customerNumber,
        apiKey,
      },
    },
  );
}

// export function GetReportData(from, to, customerNumber) {
//   return reportAxios.get(
//     `api/v1/reportData/invoiced-assignment/by-customer?from=${from}&to=${to}&customerNumber=${customerNumber}`,
//     {
//       headers: { apiKey: '39C6982C-AA64-4EE7-9D0F-DA0A342DCA62' },
//     },
//   );
// }

export function GetReportData(from, to, customerNumber, token) {
  return axios.get(
    `/CRMCustomer/CustomerStatistics?from=${from}&to=${to}&customerNumber=${customerNumber}`,
    {
      headers: { token },
    },
  );
}
