import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import history from '../../../Share/utils/history';
import { connect } from 'react-redux';
import { displayDirectBooking } from '../../actions';

const WorldishLogin = ({ onDisplayDirectBooking }) => {
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    if (pathname === '/WorldishLogin') {
      history.push({
        pathname: '/',
        state: {
          isFromWorldishLogin: true,
        },
      });
      setTimeout(() => {
        onDisplayDirectBooking();
      }, 500);
    }
  }, [pathname]);

  return <div></div>;
};

const mapDispatchToProps = dispatch => ({
  onDisplayDirectBooking: () => {
    dispatch(displayDirectBooking(true));
  },
});

export default connect(null, mapDispatchToProps)(WorldishLogin);
