import React, { useRef, useState } from 'react';
import {
  Col,
  Modal,
  Rating,
  Row,
  TextField,
  Translate,
  TransvoiceDateRangePicker,
  TransvoiceTimePickerV2,
} from '../../../Share/components';
import { generateEditOrderDateSession, isIE } from '../../../Share/utils';
import { ValidatorForm } from 'react-form-validator-core';
import './style.scss';
import StarGroup from '../StarGroup';
import moment from 'moment';
import { MONTHS } from '../../../Share/constants';

const TimeReport = ({ onHandleModal, assignmentDetail }) => {
  const [comment, setComment] = useState('');
  const {
    OrderNumber,
    Resource: { ResourceFullName },
    DatetimeFrom,
    DatetimeTo,
  } = assignmentDetail;

  const orderDateTimeRender = assignment => {
    moment.updateLocale('sv', {
      months: MONTHS,
    });
    const dateTimeFrom = moment(assignment.DatetimeFrom).locale('sv');
    const dateTimeTo = moment(assignment.DatetimeTo).locale('sv');

    let orderDateTimeStr = `${dateTimeFrom.format(
      'DD MMMM YYYY',
    )}, klockan ${dateTimeFrom.format('HH:mm')} - ${dateTimeTo.format(
      'HH:mm',
    )}`;

    if (!dateTimeFrom.isSame(dateTimeTo, 'day')) {
      orderDateTimeStr = `${dateTimeFrom.format(
        'HH:mm, DD MMMM',
      )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;
    }
    return orderDateTimeStr;
  };

  const dateRange = generateEditOrderDateSession(DatetimeFrom, DatetimeTo);

  const handleTextChange = e => {
    setComment(e.target.value);
  };

  const componentRef = useRef();

  return (
    <div>
      <Modal
        ref={componentRef}
        isVisible
        className="tv-workassignment__modal timereport__modal"
        onCloseClicked={onHandleModal}
        onOverlayClick={() => {}}
        headerClassName="tv-workassignment__modal-header"
        headerText={Translate({
          content: 'timeReport.title',
        })}
        ieOptionClassName={isIE ? 'ie-fix-height' : ''}
      >
        <div className="timereport">
          <ValidatorForm>
            <Row className="py-3 border-bottom timereport__general-details">
              <Col>
                <Row className="assignment-number">
                  {OrderNumber}, {ResourceFullName}
                </Row>
                <Row className="assignment-schedule-time">
                  {orderDateTimeRender(assignmentDetail)}
                </Row>
              </Col>
              <Col className="d-flex flex-column align-items-end">
                <Row>Betygsätt uppdraget</Row>
                <Row className="star-group">
                  <StarGroup
                    className="star-group-container"
                    size={40}
                    activeColor="#FF9B4E"
                    handleChange={e => {}}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="pl-3 py-3 border-bottom">
              <Row className="w-100 mb-4">Faktisk tolktid</Row>
              <Row className="w-100">
                <Col className="pl-0">
                  <div className="form-group">
                    <label>
                      <Translate content="singleAssignment.selectDatePeriod" />
                    </label>
                    <div className=" tv-single-assign-form__input">
                      <TransvoiceDateRangePicker
                        ranges={dateRange.ranges}
                        displayFormat="YYYY-MM-DD"
                        disabled={true}
                      />
                    </div>
                  </div>
                </Col>
                <Col className="pr-0">
                  <div className="form-group">
                    <label>
                      <Translate content="singleAssignment.startTime" />
                    </label>
                    <TransvoiceTimePickerV2
                      defaultValue={dateRange.startTime}
                      startFromDayBegin
                    />
                  </div>
                </Col>
                <Col className="pr-0">
                  <div className="form-group">
                    <label>
                      <Translate content="singleAssignment.endTime" />
                    </label>
                    <TransvoiceTimePickerV2
                      defaultValue={dateRange.endTime}
                      durationCalculate={dateRange.startTime}
                      startFromDayBegin
                    />
                  </div>
                </Col>
              </Row>
            </Row>
            <Row className="py-3">
              <div className="w-100">Övriga kommentarer</div>
              <div className="w-100 mt-4">
                <TextField
                  placeholder="Valfritt att lämna en kommentar"
                  multiline
                  value={comment}
                  className="tv-single-assign-form__textfield tv-single-assign-form__textarea tv-single-assign-form__control"
                  name="assignmentDescription"
                  onChange={handleTextChange}
                />
              </div>
            </Row>
            <Row>
              <Col className="pl-0">
                <button
                  onClick={onHandleModal}
                  className="timesheet-button_cancel"
                >
                  Rapportera direkt
                </button>
              </Col>
              <Col className="pr-0">
                <button
                  onClick={onHandleModal}
                  className="timesheet-button_submit"
                >
                  Fortsätt
                </button>
              </Col>
            </Row>
          </ValidatorForm>
        </div>
      </Modal>
    </div>
  );
};

export default TimeReport;
